<template>
  <div>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-10">
        <h2>Compras</h2>
        <ol class="breadcrumb">
          <li>
            Ordenes de compra
          </li>
          <li>
            <strong>
              {{modo == 'nuevo'?'Nuevo':''}}
              {{modo == 'edicion'?'Edición':''}}
              {{modo == 'visor'?'Visor':''}}
            </strong>
          </li>
        </ol>
      </div>
    </div>

    <div class="wrapper wrapper-content">
      <div class="row">
        <div class="col-md-12">
          <div class="p-sm text-center">
            <span style="font-size:16px" class="text-warning">
              <i class="fa fa-exclamation-circle fa-lg"></i> &nbsp;
              <b>Esta orden pertenece a una cotización con múltiples proveedores</b>
            </span>
          </div>
          <div class="ibox">
            <div class="ibox-title">
              <h5>
                {{modo == 'nuevo'?'Nueva orden de compra para':''}}
                {{modo == 'edicion'?'Editar orden de compra para':''}}
                {{modo == 'visor'?'Ver orden de compra para':''}}
                cotización
              </h5>
              <span class="label" style="font-size:12px">358</span>
              <div class="ibox-tools">
                <label>
                  Estado:
                  <span 
                    v-show="modo == 'edicion'"
                    class="label label-warning"
                    style="font-size:12px">
                    pendiente
                  </span>
                </label>
                
                <a @click="cerrar()">
                  <i class="fa fa-close"></i>
                </a>
              </div>
            </div>
            
            <div v-for="n in 4" :key="n">
              <div class="p-sm text-center bg-info">
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-8">
                    <span style="font-size:16px">
                      <b>Orden para proveedor Sistemas y Servicios de Comunicación, S. A. de C. V.</b>
                    </span>
                  </div>
                  <div class="col-md-2 text-right">
                    <button class="btn btn-default btn-xs" style="color:black"><i class="fa fa-print"></i> Imprimir</button>
                  </div>
                </div>
              </div>
              
              <div class="ibox-content">
                <div class="row">
                  <div class="col-md-4">
                    <label>Proveedor</label><br><br>
                    <hr style="margin-top: 1rem; margin-bottom: 1rem;">
                    
                    <div class="contact-box">
                      <a href="#">
                        <div class="row">
                          <div class="col-sm-6 text-center">
                            <img class="img-circle img-responsive" :src="iconoProveedor">
                            <label>Sistemas y Servicios de Comunicación, S. A. de C. V.</label>
                          </div>
                          <div class="col-sm-6">
                            <h3><strong>John Smith</strong></h3>
                            <p><i class="fa fa-map-marker"></i> Av. 20 de Noviembre #805 Col Centro de la ciudad de Chihuahua</p>
                            <address>
                              Estado de Chihuahua <br>
                              CP 31000 <br>
                              <abbr title="telefono">Tel:</abbr> (614) 415 -2525
                            </address>
                          </div>
                        </div>
                        
                        <div class="clearfix"></div>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>Nombre del Artículo o servicio</th>
                            <th>Cantidad solicitada</th>
                            <th>Precio unitario</th>
                            <th>Tiempo de entrega</th>
                            <th>Observaciones</th>
                            <th>Reputación</th>
                            <th>Tipo de pago</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="n in 2" :key="n">
                            <td>
                              LiteBeam 5 AC Gen2
                            </td>
                            <td>
                              2
                            </td>
                            <td>
                              $2,000.00
                            </td>
                            <td>
                              Mismo día en sucursal
                            </td>
                            <td>
                              <textarea class="form-control" rows="3"></textarea>
                            </td>
                            <td>
                              <textarea class="form-control" rows="3"></textarea>
                            </td>
                            <td>
                              <select class="form-control">
                                <option>Seleccionar</option>
                                <option>Transferencia bancaria</option>
                                <option>Deposito</option>
                                <option>Mixto</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-right">
            <button @click="cerrar()" class="btn btn-default">
              <i class="fa fa-angle-left"></i> Atras
            </button>

            {{modo == 'nuevo'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-primary"
              @click="guardar()"
              v-if="modo == 'nuevo'"
            >
              <i class="fa fa-check"></i>
              Generar orden de compra
            </button>

            {{modo == 'edicion'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-primary"
              @click="Actualizar()"
              v-if="modo == 'edicion'"
            >
              <i class="fa fa-check"></i>
              Actualizar orden de compra
            </button>

            {{modo == 'visor'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-danger"
              v-if="modo == 'visor'"
            >
              <i class="fa fa-times-circle"></i>
              Rechazar
            </button>

            {{modo == 'visor'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-warning"
              v-if="modo == 'visor'"
            >
              <i class="fa fa-clock-o"></i>
              Posponer
            </button>

            {{modo == 'visor'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-primary"
              v-if="modo == 'visor'"
            >
              <i class="fa fa-check"></i>
              Aprobar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import FamiliaArticulosSrv from '@/services/inventario/FamiliaArticulosSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import accounting from 'accounting/accounting.js'
import icoProveedor from '@/assets/img/usuario.png'

export default {
  data: function() {
    return {
      iconoProveedor: icoProveedor,
      modo: 'nuevo'
    }
  },

  created: function() {
    var self = this

    // Determinar el modo de la vista
    if (self.$route.path.indexOf('cotizaciones/nuevo') != -1) self.modo = 'nuevo'
    if (self.$route.path.indexOf('edicion') != -1) self.modo = 'edicion'
    if (self.$route.path.indexOf('visor') != -1) self.modo = 'visor'

    switch(self.modo){
      case 'nuevo': {
        
      }
      break;
      case 'edicion': {

      }
      break;
      case 'visor': {

      }
    }
  },

  methods: {
    atras: function() {
      this.$router.go(-1)
    },

    cerrar: function() {
      this.atras()
    },
  }
}
</script>

<style>

</style>